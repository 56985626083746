























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { MainProperties } from "./MainView.vue";
import { ConfirmedOrderProperties } from "./ConfirmedOrderAView.vue";
import { AddressDialogProperties } from "./AddressDialogView.vue";
import { CheckoutDialogProperties } from "./CheckoutDialogView.vue";
import { TransferDialogProperties } from './TransferDialogView.vue';
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import ProfileAddressService from "@/services/ProfileAddressService";
import CustomerOrderService from '@/services/CustomerOrderService';

@Component({
    data: () => ({
        totalWeight: 0,
        subtotal: 0,
        shippingFee: 0,
        discountAmount: 0,
        totalAmount: 0,
        focused: false
    }),
})
export default class ConfirmedSummaryView extends Vue {
    @Prop() private main: MainProperties;
    @Prop() private properties: ConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private addressDialog: AddressDialogProperties;
    @Prop() private checkoutDialog: CheckoutDialogProperties;
    @Prop() private transferDialog: TransferDialogProperties;
    private profileAddressService = new ProfileAddressService();
    private customerOrderService = new CustomerOrderService();

    public get deliveryOptions() {
        var options = [
            {
                text: this.$t("text.standard-delivery"),
                value: "Standard-Delivery",
            },
            {
                text: this.$t("text.on-hold-delivery"),
                value: "On-Hold-Delivery",
            },
            {
                text: this.$t("text.store-pickup"),
                value: "Store-Pickup",
            },
        ];

        const allowed = this.properties.allowedDeliveryMethods;
        if (allowed.length > 0) {
            return options.filter(x => allowed.includes(x.value));
        } else {
            return [options[0]];
        }
    }

    public get voucherNumber() {
        if (!this.properties.voucherNumber && !this.$data.focused) {
            return " ";
        }
        return this.properties.voucherNumber;
    }

    public set voucherNumber(v: string) {
        this.properties.voucherNumber = v.trim();
    }

    public get shipping() {
        return this.properties.shipping;
    }

    public get currency() {
        return this.properties.currency;
    }

    public get isStandardDelivery() {
        return this.properties.deliveryMethod === "Standard-Delivery";
    }

    public deliveryMethodChanged() {
        this.calculateSummary();
    }
    
    public voucherNumberBlur() {
        this.$data.focused = false;
        if (this.properties.voucherNumber !== this.properties.voucherNumberApplied) {
            this.calculateSummary();
        }
    }

    public voucherNumberKeydown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            if (this.properties.voucherNumber !== this.properties.voucherNumberApplied) {
                this.calculateSummary();
            }
        }
    }

    public async created() {
        await this.load();
        await this.calculateSummary();

        this.properties.events.subscribe("calculate-summary", this.calculateSummary);
    }

    public destroyed() {
        this.properties.events.remove("calculate-summary", this.calculateSummary);
    }

    public async calculateSummary() {
        this.main.loading = true;

        const shipping = this.properties.shipping;
        const deliveryMethod = this.properties.deliveryMethod;
        const voucherNumber = this.properties.voucherNumber;

        const ids = this.properties.customerOrders
            .filter(x => x.selected)
            .map(x => x.id);

        try {
            const r = await this.customerOrderService.summary({
                ids,
                shippingId: shipping?.id,
                deliveryMethod,
                voucherNumber
            });
            this.properties.voucherNumberApplied = voucherNumber;

            this.$data.totalWeight = r.data.totalWeight;
            this.$data.subtotal = r.data.subtotal;
            this.$data.shippingFee = r.data.shippingFee;
            this.$data.discountAmount = r.data.discountAmount;
            this.$data.totalAmount = r.data.totalAmount;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }

            this.properties.voucherNumber = this.properties.voucherNumberApplied;
        } finally {
            this.main.loading = false;
        }
    }

    public async load() {
        try {
            var r = await this.profileAddressService.shipping({
                loadCity: true,
                loadState: true,
                loadCountry: true,
            });
            var shipping = r.data;

            if (!shipping) {
                r = await this.profileAddressService.get({
                    shipping: true,
                    loadCity: true,
                    loadState: true,
                    loadCountry: true,
                });
                if (r.data.length > 0) {
                    shipping = r.data[0];
                }
            }
            if (shipping) {
                shipping.address = AddressUtil.build(shipping);
            }

            this.properties.shipping = shipping;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public selectShipping() {
        this.addressDialog.visible = true;
    }

    public transfer() {
        const selected = this.properties.customerOrders
            .filter(x => x.selected);
        if (selected.length == 0) {
            var m = this.$t("message.transfer-nothing-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            this.transferDialog.visible = true;
        }
    }

    public checkout() {
        const selected = this.properties.customerOrders
            .filter(x => x.selected);
        if (selected.length == 0) {
            var m = this.$t("message.checkout-nothing-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            this.checkoutDialog.totalAmount = this.$data.totalAmount;
            this.checkoutDialog.visible = true;
        }
    }
}
