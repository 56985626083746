import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerOrderService extends AbstractService {
    private controller = "customer-orders";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public summary(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/summary`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }    

    public finalize(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}/finalize`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public cancel(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}/cancel`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
    
    public reject(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}/reject`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public reverse(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}/reverse`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
    
    public delivered(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}/delivered`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(customerOrder: any) {
        const d = { customerOrder };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}